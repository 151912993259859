export default class Distribution {
  constructor(url, version, lastModifiedPrettyDate) {
    this.url = url
    this.version = version
    this.lastModifiedPrettyDate = lastModifiedPrettyDate
  }

  static empty() {
    return new Distribution("", "", "")
  }

  static fromData(data) {
    return this.from(data.distributionList)
  }

  static from(data) {
    const { url, version, lastModifiedPrettyDate } = data
    return new Distribution(url, version, lastModifiedPrettyDate)
  }
}
